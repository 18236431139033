<template>
  <v-data-table
    :headers="headers"
    :items="products"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Products</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Product
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.category"
                      :items="categoryItems"
                      error-messages="selectErrors"
                      label="Category"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.unit"
                      label="Unit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="editedItem.price"
                      label="Price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-file-input
                      accept="image/*"
                      @change="uploadImage"
                      label="Product Image"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.image"
                      label="Image"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Product Description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="products"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { db, st } from "../../firebase";

export default {
  data: () => ({
    imageData: File,
    categoryItems: [
      "Groceries",
      "Soaps and Detergents",
      "Fruits",
      "Vegetables",
      "Beverages",
      "Traditionals",
      "Livestock",
      "Clothing",
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Id", align: "start", value: "id" },
      { text: "Name", sortable: true, value: "name" },
      { text: "Category", value: "category", sortable: true },
      { text: "Unit", value: "unit" },
      { text: "Price", value: "price" },
      { text: "Description", value: "description" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      category: "",
      unit: "",
      price: 0,
      image: "",
      imagePath: "",
      description: "",
      id: "",
    },
    defaultItem: {
      name: "",
      category: "",
      unit: "",
      price: 0,
      image: "",
      imagePath: "",
      description: "",
      id: "",
    },
  }),

  beforeCreate: function () {
    this.$store.dispatch("getProducts");
  },

  computed: {
    products() {
      return this.$store.getters.products;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    initialize() {
      this.$store.dispatch("getProducts");
    },
    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItemConfirm() {
      db.collection("products")
        .doc(this.editedItem.id)
        .delete()
        .then(() => {
          this.dialogDelete = false;
          //var ref = st.child(item.imagePath);
          //ref
          //.delete()
          //.then(() => {
          //console.log("Deleted")
          //})
          //.catch(error => {
          //console.error("Error removing file: ", error)
          //});
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    uploadImage(event) {
      this.imageData = event;
    },

    save() {
      var product = this.editedItem;
      if (this.editedIndex > -1) {
        var updatedProduct = db.collection("products").doc(product.id);
        return updatedProduct
          .update({
            name: product.name,
            category: product.category,
            unit: product.unit,
            price: product.price,
            image: product.image,
            description: product.description,
          })
          .then(function () {
            console.log("Document successfully updated!");
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      } else {
        let uploadTask = st
          .ref("products/" + this.imageData.name)
          .put(this.imageData);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot);
          },
          (error) => {
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              product.image = downloadURL;
              product.imagePath = "products/" + this.imageData.name;
              console.log(product);
              db.collection("products")
                .doc()
                .set(product)
                .then(function () {
                  this.initialize;
                })
                .catch(function (docRef) {
                  console.log(docRef.id);
                });
            });
          }
        );
      }
      this.close();
    },
  },
};
</script>
